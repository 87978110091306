<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-body" >
          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">基础信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">姓名</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.name }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">电话号码</span>
                      <span class="descriptions-item-content">{{ farmer && farmer.phone }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">身份证号</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.idNo }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">银行卡号</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.bankNo }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">所在地区</span>
                      <span class="descriptions-item-content">
                        {{ farmer && [farmer.province, farmer.city, farmer.area].join('') }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">详细地址</span>
                      <span class="descriptions-item-content">
                        {{ farmer && farmer.address }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions" style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">并网信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">并网状态</span>
                      <span class="descriptions-item-content">
                          {{ gridConnectionStatusMapper(metadata.state) }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
              <div>
                <el-form :model="pageForm"
                         :rules="pageFormRules"
                         ref="formRef"
                         label-position="left"
                         label-width="140px">
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="户号" prop="generatorNo" required >
                        <el-input v-model="pageForm.generatorNo" :maxlength="15" style="width: 100%;"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :offset="2" :span="8"></el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="装机容量" prop="capacity" required >
                        <el-input v-model="pageForm.capacity" :maxlength="15" style="width: 100%;">
                          <template slot="append">瓦</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :offset="2" :span="8">
                      <el-form-item label="意向消纳方式" prop="consumeType" required >
                        <el-select v-model="pageForm.consumeType" clearable>
                          <el-option v-for="(label, value) in INTENTION_CONSUMPTION_STATE"
                                     :key="value"
                                     :label="label"
                                     :value="value"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="是否本人办理" prop="oneselfStr" required >
                        <el-radio v-model="pageForm.oneselfStr" :label="true">是</el-radio>
                        <el-radio v-model="pageForm.oneselfStr" :label="false">否</el-radio>
                      </el-form-item>
                    </el-col>
                    <el-col :offset="2" :span="8"></el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="供电合同签订日期" prop="contractTime" required >
                        <el-date-picker v-model="pageForm.contractTime" type="date" value-format="yyyy-MM-dd"></el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :offset="2" :span="8"></el-col>
                  </el-row>

                  <el-row>
                    <el-row>
                      <el-col :span="12">
                        <el-form-item prop="files" required label="供电合同">
                          <el-upload class="upload-demo"
                                     action=""
                                     ref="filesUpload"
                                     list-type="picture"
                                     :multiple="true"
                                     :limit="5"
                                     :file-list="fileList"
                                     :show-file-list="false"
                                     :http-request="handlerUpload" >
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">word, PDF, RAR, ZIP, JPG, PNG, 单个文件不能超过100MB，最多上传5个文件</div>
                          </el-upload>
                          <ul class="el-upload-list el-upload-list--picture">
                            <li v-for="(iter, index) in fileList" :key="iter.name"
                                tabindex="0" class="el-upload-list__item is-success">
                              <img v-show="isImage(iter.name)" style="object-fit: cover;"
                                   :src="iter.url" alt="" class="el-upload-list__item-thumbnail">
                              <img v-show="!isImage(iter.name)" src="@/assets/img/file.png" alt="" class="el-upload-list__item-thumbnail">
                              <a class="el-upload-list__item-name">
                                <i class="el-icon-document"></i>{{ iter.name }}
                              </a>
                              <label class="el-upload-list__item-status-label">
                                <i class="el-icon-upload-success el-icon-check"></i>
                              </label>
                              <i @click="removeHandler(index)" class="el-icon-close"></i>
                            </li>
                          </ul>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item prop="img" required label="并网图片">
                        <el-upload accept="image/*"
                                   class="upload-demo"
                                   action=""
                                   :multiple="true"
                                   ref="filesImgUpload"
                                   list-type="picture-card"
                                   :file-list="imageList"
                                   :on-remove="(f, fl) => this.imageList = fl"
                                   :http-request="handlerUploadImage">
                          <i class="el-icon-plus"></i>
                        </el-upload>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>

          <div
               class="descriptions"
               style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">操作</div>
            </div>
            <div class="descriptions-view">
              <el-button @click="closePage">取消</el-button>
              <el-button type="primary" @click="submit">提交</el-button>
            </div>
          </div>

          <!-- 审核信息 -->
          <div v-if="metadata && metadata.state === 'EXAMINE'"
               class="descriptions"
               style="padding: 20px;">
            <div class="descriptions-header">
              <div class="descriptions-title">审核</div>
            </div>
            <div class="descriptions-view">
              <el-button type="primary" size="mini" @click="examineInfo">审核</el-button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {connectionApply, connectionExamine, fileUpload, getConnectionById} from "@/api/apis";
import {exportPicture, gridFileTypeVerifily, isImage, viewImages} from "@/utils/common";
import helper, {gridConnectionStatusMapper, statusMapper} from "@/utils/helper";
import {INTENTION_CONSUMPTION_STATE} from "@/utils/config";
import {examineNotMsg} from "@/pageInteraction/examine/ExamineMsg";
export default {
  name: "Info",
  components: {PageHeader},
  data(){
    return {
      getData: getConnectionById,
      id: '',
      metadata: {},

      farmer: null,
      workOrder: null,
      roofTypeModel: null,
      user: null,
      designData: {},
      INTENTION_CONSUMPTION_STATE,
      designDataFiles:[],
      fileList: [],
      // 并网图片
      imageList: [],
      //
      pageForm: {
        // 户号
        generatorNo: '',
        // 装机容量
        capacity: '',
        // 意向消纳方式
        consumeType: 'ALL',
        // 是否本人办理
        oneselfStr: true,
        // 供电合同签署日期
        contractTime: '',
        // 供电合同
        files: [],
        // 并网图片
        img: [],
      },
      pageFormRules: {
        generatorNo: [
          { required: true, message: '请填写户号', },
        ],
        capacity: [
          { required: true, message: '请填写装机容量', },
        ],
        consumeType: [
          { required: true, message: '请选择意向消纳方式', },
        ],
        oneselfStr: [
          { required: true, message: '请确认是否本人办理', },
        ],
        contractTime: [
          { required: true, message: '请选择供电合同签订日期', },
        ],
        files: [
          { required: true, message: '请上传供电合同', },
        ],
        img: [
          { required: true, message: '请上传并网图片', },
        ],
      },
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getInfo()
  },
  methods: {
    isImage,
    gridConnectionStatusMapper,
    viewImages,
    statusMapper,
    /**
     * 关闭
     */
    closePage(){
      this.$router.replace('/admin/grid-connection')
    },
    /**
     * 提交
     */
    submit(){
      this.$refs.formRef.validate((valid) => {
        if (!valid) {
          return false
        }

        // 参数
        const params = { ...this.pageForm }
        params.connectionId = this.id
        //-
        const files = []
        this.fileList.forEach(iter => {
          files.push(iter.value)
        })
        params.files = files.join(',')
        //-
        const files2 = []
        this.imageList.forEach(iter => {
          files2.push(iter.value)
        })
        params.img = files2.join(',')

        // 提交
        const loading = this.$loading({ fullscreen: true })
        connectionApply(params).then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.closePage()
          }, 500)
        }).finally(() => {
          loading.close()
        })
      })
    },
    /**
     * 自定义文件上传
     */
    handlerUpload(e){
      const res = gridFileTypeVerifily(e.file.name)
      if (res) {
        const loading = this.$loading({ fullscreen: true })
        fileUpload(e.file).then(res => {
          const name = ((res || '').split('/') || []).reverse()[0]
          this.fileList.push({
            name: name,
            url: helper.getSourceUri(res),
            value: res
          })
          this.pageForm.files.push(res)
        }).finally(() => {
          loading.close()
        })
      } else {
        this.$message.warning('文件格式不正确')
      }
      this.$refs.filesUpload.uploadFiles = this.$refs.filesUpload.uploadFiles.filter(iter => iter.status === 'success')
    },
    /**
     * 图片上传
     * @param e
     */
    handlerUploadImage(e){
      const loading = this.$loading({ fullscreen: true })
      fileUpload(e.file).then(res => {
        const name = ((res || '').split('/') || []).reverse()[0]
        this.imageList.push({
          name: name,
          url: helper.getSourceUri(res),
          value: res
        })
        this.pageForm.img.push(res)
      }).finally(() => {
        loading.close()
      })

      this.$refs.filesImgUpload.uploadFiles = this.$refs.filesImgUpload.uploadFiles.filter(iter => iter.status === 'success')
    },
    /**
     * 移除文件
     * @param index
     */
    removeHandler(index){
      this.fileList.splice(index, 1)
    },
    /**
     * 点击文件
     * @param file
     */
    onPreviewHandler(file){
      const { url, name } = file
      exportPicture(url, name)
    },
    /**
     * 审核
     */
    examineInfo(){
      examineNotMsg(info => {
        const params = {
          id: this.id,
          state: info.state,
        }

        const loading = this.$loading({ fullscreen: true })
        connectionExamine(params).then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.$refs.table && this.$refs.table.loadData()
          }, 500)
        }).finally(() => {
          loading.close()
        })

      })
    },
    /**
     *
     */
    showFileList(item){
      const filesStr = item?.files || ''
      const files = []
      filesStr.split(',').forEach(iter => {
        const name = ((iter || '').split('/') || []).reverse()[0]
        iter && files.push({
          name: name,
          url: helper.getSourceUri(iter),
          value: iter
        })
      })
      return files
    },
    /**
     * 获取数据
     */
    getInfo(){
      if (this.id) {
        this.getData(this.id).then(res => {
          this.metadata = res
          //
          this.farmer = this.metadata?.farmer
          this.workOrder = this.metadata?.workOrder
          this.roofTypeModel = this.metadata?.workOrder?.roofTypeModel
          this.user = this.metadata?.workOrder?.user
          this.designData = this.metadata?.designData

          this.designDataFiles = this.showFileList(this.metadata)

        })
      } else {
        this.$message.error('页面异常, 请返回重新进入')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>